@import '@src/components/media/editor/assets/scss/_inc';

.bf-modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  button {
    outline: none;
  }
}

.bf-modal-mask {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.1);
  opacity: 0;
  transition: opacity 0.2s;
}

.bf-modal-content {
  position: absolute;
  z-index: 2;
  top: 45%;
  left: 50%;
  max-width: 95%;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 15px 30px rgba(#000, 0.1);
  opacity: 0;
  transform: translate(-50%, -40%);
  transition: transform 0.2s, opacity 0.2s;
}

.bf-modal-header {
  height: 50px;
}

.bf-modal-caption {
  float: left;
  margin: 0;
  padding: 0 15px;
  color: rgba(#999, 1);
  font-size: 14px;
  font-weight: normal;
  line-height: 50px;
}

.bf-modal-close-button {
  float: right;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  color: #ccc;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $COLOR_DANGER;
  }
}

.bf-modal-body {
  overflow: auto;
}

.bf-modal-footer {
  min-height: 15px;
  padding: 0 15px;
  overflow: hidden;
}

.bf-modal-addon-text {
  float: left;
  color: #999;
  font-size: 12px;
  line-height: 60px;
}

.bf-modal-buttons {
  float: right;
}

.bf-modal-cancel,
.bf-modal-confirm {
  height: 36px;
  margin: 12px 0 12px 15px;
  padding: 0 30px;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.bf-modal-cancel {
  background-color: #e8e9ea;
  color: #999;

  &:hover {
    background-color: #d8d9da;
  }
}

.bf-modal-confirm {
  background-color: $COLOR_ACTIVE;
  color: #fff;

  &:hover {
    background-color: $COLOR_ACTIVE;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    filter: grayscale(0.4);
  }
}

.bf-modal-root.active {
  .bf-modal-mask {
    opacity: 1;
  }

  .bf-modal-content {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}