.box {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image {
  width: 100%;
  max-width: 555px;
  margin-bottom: 62px;
}

.text {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 1.42px;
  color: #222222;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 2.125rem;
  }
}
@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;