@import '@src/components/media/editor/assets/scss/_inc';

.bf-switch {
  position: relative;
  width: 32px;
  height: 16px;
  background-color: rgba(#fff, 0.15);
  border-radius: 8px;
  transition: background 0.3s;
  &.active {
    background-color: $COLOR_ACTIVE;
    &::before {
      left: 16px;
    }
  }
  &::before {
    position: absolute;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #eee;
    content: '';
    transform: scale(1.2);
    transition: 0.3s;
  }
}
