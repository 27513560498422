.selectBox {
  width: 100%;

  &:global(.ant-select):not(:global(.ant-select-disabled)):hover :global(.ant-select-selector) {
    border-color: #000000;
  }

  &:global(.ant-select-focused):global(.ant-select-single):not(:global(.ant-select-customize-input)) :global(.ant-select-selector) {
    border-color: #000000;
    box-shadow: unset;
  }

  &:global(.ant-select-selector) {
    &:hover {
      border-color: #030303;
    }
  }

  &:global(.ant-select-selection-item) {
    color: #676767;
    font-weight: 300;
    font-size: 1rem;
    padding: 12px 0 13px;

    &:hover {
      opacity: 1;
    }
  }
}

.icon {
  margin-right: 9px;
}
@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;