@import '@src/components/media/editor/assets/scss/_inc';

.bf-content {
  height: 500px;
  padding-bottom: 10px;
  overflow: auto;
  font-size: 16px;

  img {
    user-select: none;
  }

  * {
    line-height: initial;
  }
}

.bf-container {
  position: relative;
  height: 100%;
  padding: 0;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    filter: grayscale(70%);
  }

  &.read-only {
    .bf-controlbar {
      pointer-events: none;
    }

    .bf-image {
      img:hover {
        outline: none;
      }
    }

    .bf-hr {
      pointer-events: none;
    }
  }

  &.fullscreen {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    height: 100% !important;

    .bf-content {
      flex-grow: 1;
      height: auto;
    }
  }

  .input-group {
    display: block;

    input {
      box-sizing: border-box;
      width: 100%;
      height: 36px;
      padding: 0 15px;
      font-size: 14px;
    }
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  button {
    line-height: initial;

    &.ghost,
    &.default,
    &.primary {
      height: 32px;
      padding: 0 20px;
      color: #fff;
      font-size: 12px;
    }

    &.default {
      background-color: rgba(#fff, 0.15);
      border: none;

      &:hover {
        background-color: rgba(#fff, 0.1);
      }
    }

    &.ghost {
      background-color: transparent;
      border: none;
      box-shadow: inset 0 0 0 0.5px rgba(#fff, 0.5);

      &:hover {
        box-shadow: inset 0 0 0 0.5px rgba(#fff, 0.7);
      }
    }

    &.primary {
      background-color: $COLOR_ACTIVE;
      border: none;
      color: #fff;

      &:hover {
        background-color: $COLOR_ACTIVE;
      }
    }
  }

  .public-DraftEditorPlaceholder-root {
    top: 15px;
    left: 15px;
    font-size: 16px;
    pointer-events: none;
  }

  .DraftEditor-editorContainer {
    box-sizing: border-box;
    border: none;
  }

  .DraftEditor-root,
  .public-DraftEditor-content {
    height: 100%;
  }

  .public-DraftEditor-content {
    box-sizing: border-box;
    padding: 15px;
    word-wrap: break-word;
    word-break: break-all;

    >div {
      padding-bottom: 20px;
    }

    .braft-link {
      color: #4078c0;
    }

    // blockquote {
    //   margin: 0 0 10px 0;
    //   padding: 15px 20px;
    //   background-color: #f1f2f3;
    //   border-left: solid 5px #ccc;
    //   color: #666;
    //   font-style: italic;
    // }

    pre {
      max-width: 100%;
      max-height: 100%;
      margin: 10px 0;
      padding: 15px;
      overflow: auto;
      background-color: #f1f2f3;
      border-radius: 3px;
      color: #666;
      font-family: monospace;
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      word-wrap: break-word;
      white-space: pre-wrap;

      pre {
        margin: 0;
        padding: 0;
      }
    }
  }

  .bfa-left {
    text-align: left;

    .public-DraftStyleDefault-ltr {
      text-align: left;
    }
  }

  .bfa-right {
    text-align: right;

    .public-DraftStyleDefault-ltr {
      text-align: right;
    }
  }

  .bfa-center {
    text-align: center;

    .public-DraftStyleDefault-ltr {
      text-align: center;
    }
  }

  .bfa-justify {
    text-align: justify;

    .public-DraftStyleDefault-ltr {
      text-align: justify;
    }
  }

  .bfa-left,
  .bfa-right,
  .bfa-center,
  .bfa-justify {
    &>div {
      display: inline-block;
    }
  }

  .bff-left,
  .bff-right {
    &:hover {
      z-index: 2;
    }
  }

  .bff-left {
    position: relative;
    z-index: 1;
    float: left;
    margin: 0 10px 0 0;
  }

  .bff-right {
    position: relative;
    z-index: 1;
    float: right;
    margin: 0 0 0 10px;
  }

  .bftd-1 {
    text-indent: 2em;
    display: initial;
  }

  .bftd-2 {
    text-indent: 4em;
    display: initial;
  }

  .bftd-3 {
    text-indent: 6em;
    display: initial;
  }

  .bftd-4 {
    text-indent: 8em;
    display: initial;
  }

  .bftd-5 {
    text-indent: 10em;
    display: initial;
  }

  .bftd-6 {
    text-indent: 12em;
    display: initial;
  }

  .bf-media {
    position: relative;
  }

  .bf-image {
    position: relative;

    img {
      display: block;
      max-width: 100%;
      font-size: 0;
      resize: both;
      outline-offset: 1px;

      &:hover {
        outline: solid 1px $COLOR_ACTIVE;
      }
    }
  }

  .bf-media-toolbar {
    position: absolute;
    z-index: 3;
    bottom: 15px;
    left: 50%;
    width: auto;
    background-color: $COLOR_DARK;
    border-radius: 2px;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
    box-shadow: 0 5px 15px rgba(#000, 0.2);
    user-select: none;

    &::before,
    .bf-media-toolbar-arrow {
      position: absolute;
      bottom: -10px;
      left: 50%;
      display: block;
      border: solid 5px transparent;
      border-top-color: $COLOR_DARK;
      content: '';
      transform: translateX(-5px);
    }

    a {
      display: inline-block;
      min-width: 40px;
      height: 40px;
      color: rgba(#fff, 0.5);
      font-family: 'braft-icons' !important;
      font-size: 18px;
      font-weight: normal;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: #fff;
      }

      &:first-child {
        border-radius: 2px 0 0 2px;
      }

      &:last-child {
        border-radius: 0 2px 2px 0;
      }

      &.active {
        color: $COLOR_ACTIVE;
      }
    }
  }
}