@import '@src/components/media/editor/assets/scss/_inc';

.bf-font-size-dropdown {
  min-width: 55px;
}

.bf-font-sizes {
  box-sizing: content-box;
  width: 210px;
  list-style: none;
  margin: 0;
  padding: 5px;
  overflow: hidden;

  li {
    display: block;
    float: left;
    width: 60px;
    height: 30px;
    background-color: rgba(#fff, 0.1);
    border-radius: 2px;
    margin: 5px;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: rgba(#fff, 0.2);
    }

    &.active {
      background-color: $COLOR_ACTIVE;
    }
  }
}