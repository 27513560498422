.header {
  width: 100%;
  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
  z-index: 99;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  .topBar {
    padding: 5px;
    width: 100%;
    background-color: #676767;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .topBarText {
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.54px;
      color: #ffffff;
    }

    .topBarClose {
      cursor: pointer;
      position: absolute;
      right: 30px;
    }
  }

  .headerBar {
    height: 52px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 35px;

    .list {
      height: 100%;
      display: inline-flex;
      align-items: center;
      max-width: 50%;
    }

    .item {
      height: 100%;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      margin-right: 24px;


      .itemTitle {
        display: flex;
        align-items: center;
        height: 51px;
        white-space: nowrap;
        &:hover {
          color: #3091FD;
          font-weight: 500;
          border-top: 2px solid transparent;
          border-bottom: 2px solid #3091FD;
        }
      }
    }

    .headerNavRight {
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 0 35px;
      z-index: 9;

      .navDiv {
        white-space: nowrap;
        cursor: pointer;
        margin-left: 35px;

        &:first-child {
          margin-left: 0px;
        }
      }
    }

    .userProfile {
      height: 100%;
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        .userProfileInBox {
          display: block;
        }
      }

      .userProfileImage {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.17);
        border: solid 0.5px #eaeaea;
      }

      .userName {
        .userProfileImage;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: white;
        background: #1150ae;
      }
    }

    .boxList {
      display: flex;
      flex-direction: column;

      .listIcon {
        margin-right: 10px;
      }

      .boxItem {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        background: white;
        padding: 15px 35px;
        border-bottom: solid 1px #dbdbdb;

        &:hover {
          background-color: #ffefe2;
        }

        .boxItemText {
          font-size: 16px;
          font-weight: 300;
          color: #222222;

          &:hover {
            font-size: 16px;
            color: #222222;
          }
        }
      }

      .alignCenter {
        justify-content: center;
      }
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;