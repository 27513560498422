.oauthBtn {
  width: 100%;
  height: 40px;
  padding: 0 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  margin: 5px 16px 5px 0;
  cursor: pointer;

  .oauthBtnText {
    font-size: 1rem;
    font-weight: 500;
    color: #676767;
    width: 145px;
    text-align: left;
  }

  &.binded {
    background-color: #d1d1d1;
    cursor: initial;

    & .oauthBtnText {
      color: #ffffff;
    }
  }
}

.icon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 18px;
}

.google {
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4Ij48Zz48cGF0aCBkPSJtMTIwIDI1NmMwLTI1LjM2NyA2Ljk4OS00OS4xMyAxOS4xMzEtNjkuNDc3di04Ni4zMDhoLTg2LjMwOGMtMzQuMjU1IDQ0LjQ4OC01Mi44MjMgOTguNzA3LTUyLjgyMyAxNTUuNzg1czE4LjU2OCAxMTEuMjk3IDUyLjgyMyAxNTUuNzg1aDg2LjMwOHYtODYuMzA4Yy0xMi4xNDItMjAuMzQ3LTE5LjEzMS00NC4xMS0xOS4xMzEtNjkuNDc3eiIgZmlsbD0iI2ZiYmQwMCIvPjxwYXRoIGQ9Im0yNTYgMzkyLTYwIDYwIDYwIDYwYzU3LjA3OSAwIDExMS4yOTctMTguNTY4IDE1NS43ODUtNTIuODIzdi04Ni4yMTZoLTg2LjIxNmMtMjAuNTI1IDEyLjE4Ni00NC4zODggMTkuMDM5LTY5LjU2OSAxOS4wMzl6IiBmaWxsPSIjMGY5ZDU4Ii8+PHBhdGggZD0ibTEzOS4xMzEgMzI1LjQ3Ny04Ni4zMDggODYuMzA4YzYuNzgyIDguODA4IDE0LjE2NyAxNy4yNDMgMjIuMTU4IDI1LjIzNSA0OC4zNTIgNDguMzUxIDExMi42MzkgNzQuOTggMTgxLjAxOSA3NC45OHYtMTIwYy00OS42MjQgMC05My4xMTctMjYuNzItMTE2Ljg2OS02Ni41MjN6IiBmaWxsPSIjMzFhYTUyIi8+PHBhdGggZD0ibTUxMiAyNTZjMC0xNS41NzUtMS40MS0zMS4xNzktNC4xOTItNDYuMzc3bC0yLjI1MS0xMi4yOTloLTI0OS41NTd2MTIwaDEyMS40NTJjLTExLjc5NCAyMy40NjEtMjkuOTI4IDQyLjYwMi01MS44ODQgNTUuNjM4bDg2LjIxNiA4Ni4yMTZjOC44MDgtNi43ODIgMTcuMjQzLTE0LjE2NyAyNS4yMzUtMjIuMTU4IDQ4LjM1Mi00OC4zNTMgNzQuOTgxLTExMi42NCA3NC45ODEtMTgxLjAyeiIgZmlsbD0iIzNjNzllNiIvPjxwYXRoIGQ9Im0zNTIuMTY3IDE1OS44MzMgMTAuNjA2IDEwLjYwNiA4NC44NTMtODQuODUyLTEwLjYwNi0xMC42MDZjLTQ4LjM1Mi00OC4zNTItMTEyLjYzOS03NC45ODEtMTgxLjAyLTc0Ljk4MWwtNjAgNjAgNjAgNjBjMzYuMzI2IDAgNzAuNDc5IDE0LjE0NiA5Ni4xNjcgMzkuODMzeiIgZmlsbD0iI2NmMmQ0OCIvPjxwYXRoIGQ9Im0yNTYgMTIwdi0xMjBjLTY4LjM4IDAtMTMyLjY2NyAyNi42MjktMTgxLjAyIDc0Ljk4LTcuOTkxIDcuOTkxLTE1LjM3NiAxNi40MjYtMjIuMTU4IDI1LjIzNWw4Ni4zMDggODYuMzA4YzIzLjc1My0zOS44MDMgNjcuMjQ2LTY2LjUyMyAxMTYuODctNjYuNTIzeiIgZmlsbD0iI2ViNDEzMiIvPjwvZz48L3N2Zz4K');
}

.facebook {
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIgaGVpZ2h0PSI1MTJweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iNTEycHgiPjxwYXRoIGQ9Im0xNS45OTcgMy45ODVoMi4xOTF2LTMuODE2Yy0uMzc4LS4wNTItMS42NzgtLjE2OS0zLjE5Mi0uMTY5LTYuOTMyIDAtNS4wNDYgNy44NS01LjMyMiA5aC0zLjQ4N3Y0LjI2NmgzLjQ4NnYxMC43MzRoNC4yNzR2LTEwLjczM2gzLjM0NWwuNTMxLTQuMjY2aC0zLjg3N2MuMTg4LTIuODI0LS43NjEtNS4wMTYgMi4wNTEtNS4wMTZ6IiBmaWxsPSIjM2I1OTk5Ii8+PC9zdmc+Cg==');
}

.line {
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIgaGVpZ2h0PSI1MTJweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iNTEycHgiPjxwYXRoIGQ9Im0xMiAuNWMtNi42MTUgMC0xMiA0LjM5OC0xMiA5LjgwMyAwIDQuODQxIDQuMjcgOC44OTcgMTAuMDM1IDkuNjY4LjM5MS4wODMuOTIzLjI2IDEuMDU4LjU5NC4xMi4zMDMuMDc5Ljc3MS4wMzggMS4wODdsLS4xNjQgMS4wMjZjLS4wNDUuMzAzLS4yNCAxLjE5MyAxLjA0OS42NDkgMS4yOTEtLjU0MiA2LjkxNi00LjEwNCA5LjQzNi03LjAxOSAxLjcyNC0xLjkgMi41NDgtMy44NDcgMi41NDgtNi4wMDUgMC01LjQwNS01LjM4NS05LjgwMy0xMi05LjgwM3ptLTQuNjk3IDEzLjAxN2gtMi4zODZjLS4zNDUgMC0uNjMtLjI4Ny0uNjMtLjYzM3YtNC44MDFjMC0uMzQ3LjI4NS0uNjM0LjYzLS42MzQuMzQ4IDAgLjYzLjI4Ny42My42MzR2NC4xNjdoMS43NTZjLjM0OCAwIC42MjkuMjg1LjYyOS42MzQgMCAuMzQ2LS4yODIuNjMzLS42MjkuNjMzem0yLjQ2Ni0uNjMzYzAgLjM0Ni0uMjgyLjYzMy0uNjMxLjYzMy0uMzQ1IDAtLjYyNy0uMjg3LS42MjctLjYzM3YtNC44MDFjMC0uMzQ3LjI4Mi0uNjM0LjYzLS42MzQuMzQ2IDAgLjYyOC4yODcuNjI4LjYzNHptNS43NDEgMGMwIC4yNzItLjE3NC41MTMtLjQzMi42LS4wNjQuMDIxLS4xMzMuMDMxLS4xOTkuMDMxLS4yMTEgMC0uMzkxLS4wOTEtLjUxLS4yNTJsLTIuNDQzLTMuMzM4djIuOTU4YzAgLjM0Ni0uMjc5LjYzMy0uNjMxLjYzMy0uMzQ2IDAtLjYyNi0uMjg3LS42MjYtLjYzM3YtNC44YzAtLjI3Mi4xNzMtLjUxMy40My0uNTk5LjA2LS4wMjMuMTM2LS4wMzMuMTk0LS4wMzMuMTk1IDAgLjM3NS4xMDUuNDk1LjI1NmwyLjQ2MiAzLjM1MXYtMi45NzVjMC0uMzQ3LjI4Mi0uNjM0LjYzLS42MzQuMzQ1IDAgLjYzLjI4Ny42My42MzR6bTMuODU1LTMuMDM1Yy4zNDkgMCAuNjMuMjg3LjYzLjYzNSAwIC4zNDctLjI4MS42MzQtLjYzLjYzNGgtMS43NTV2MS4xMzJoMS43NTVjLjM0OSAwIC42My4yODUuNjMuNjM0IDAgLjM0Ni0uMjgxLjYzMy0uNjMuNjMzaC0yLjM4NmMtLjM0NSAwLS42MjctLjI4Ny0uNjI3LS42MzN2LTQuODAxYzAtLjM0Ny4yODItLjYzNC42My0uNjM0aDIuMzg2Yy4zNDYgMCAuNjI3LjI4Ny42MjcuNjM0IDAgLjM1MS0uMjgxLjYzNC0uNjMuNjM0aC0xLjc1NXYxLjEzMnoiIGZpbGw9IiM0Y2FmNTAiLz48L3N2Zz4K');
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;