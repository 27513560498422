.button {
  border-radius: 2px;
  height: 45px;

  & span {
    font-size: 16px;
  }

  &:active,
  &:hover {
    background-color: @primary-dark-color;
    border: solid 1px @primary-dark-color;
  }
}

.defaultButton {
  .button;
  border: solid 1px @primary-color !important;

  & span {
    color: @primary-color;
  }

  &:active,
  &:hover {
    background-color: #e7f4f9;
  }
}

.title {
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
  text-align: center;
  padding-bottom: 15px;
}

.bigTitle {
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  padding-bottom: 15px;
}

.subTitle {
  font-weight: 300;
  text-align: center;
}

.messageText {
  height: 16px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: @text-color;
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  line-height: 1.5;
  color: @primary-color;
  cursor: pointer;

  &:active {
    color: @primary-dark-color;
  }

  &:hover {
    color: @primary-dark-color;
  }
}

.error {
  border: solid 1px @error-color !important;
}

@media (max-width: 768px) {
  .bigTitle {
    font-size: 24px;
  }
}

.line {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: solid 0.5px #d3d3d3;
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;