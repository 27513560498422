$woffPath: '../icons/icons.woff';

@font-face {
  font-family: 'braft-icons';
  src: url($woffPath);
  font-weight: normal;
  font-style: normal;
}

.bf-container,
.bf-modal-root {

  [class^="bfi-"],
  [class*=" bfi-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'braft-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .bfi-table:before {
    content: "\e228";
  }

  .bfi-clear_all:before {
    content: "\e0b8";
  }

  .bfi-format_clear:before {
    content: "\e239";
  }

  .bfi-hr:before {
    content: "\e925";
  }

  .bfi-colorize:before {
    content: "\e3b8";
  }

  .bfi-crop_free:before {
    content: "\e3c2";
  }

  .bfi-pause:before {
    content: "\e034";
  }

  .bfi-play_arrow:before {
    content: "\e037";
  }

  .bfi-bin:before {
    content: "\e9ac";
  }

  .bfi-replay:before {
    content: "\e042";
  }

  .bfi-tune:before {
    content: "\e429";
  }

  .bfi-close:before {
    content: "\e913";
  }

  .bfi-align-center:before {
    content: "\e028";
  }

  .bfi-align-justify:before {
    content: "\e026";
  }

  .bfi-align-left:before {
    content: "\e027";
  }

  .bfi-align-right:before {
    content: "\e029";
  }

  .bfi-image-right:before {
    content: "\e914";
  }

  .bfi-image-left:before {
    content: "\e91e";
  }

  .bfi-music:before {
    content: "\e90e";
  }

  .bfi-camera:before {
    content: "\e911";
  }

  .bfi-copy:before {
    content: "\e92c";
  }

  .bfi-file-text:before {
    content: "\e926";
  }

  .bfi-film:before {
    content: "\e91c";
  }

  .bfi-github:before {
    content: "\eab0";
  }

  .bfi-ltr:before {
    content: "\ea74";
  }

  .bfi-page-break:before {
    content: "\ea68";
  }

  .bfi-pagebreak:before {
    content: "\ea6e";
  }

  .bfi-paint-format:before {
    content: "\e90c";
  }

  .bfi-paste:before {
    content: "\e92d";
  }

  .bfi-pilcrow:before {
    content: "\ea73";
  }

  .bfi-pushpin:before {
    content: "\e946";
  }

  .bfi-redo:before {
    content: "\e968";
  }

  .bfi-rtl:before {
    content: "\ea75";
  }

  .bfi-spinner:before {
    content: "\e980";
  }

  .bfi-subscript:before {
    content: "\ea6c";
  }

  .bfi-superscript:before {
    content: "\ea6b";
  }

  .bfi-undo:before {
    content: "\e967";
  }

  .bfi-media:before {
    content: "\e90f";
  }

  .bfi-add:before {
    content: "\e918";
  }

  .bfi-bold:before {
    content: "\e904";
  }

  .bfi-code:before {
    content: "\e903";
  }

  .bfi-done:before {
    content: "\e912";
  }

  .bfi-drop-down:before {
    content: "\e906";
  }

  .bfi-drop-up:before {
    content: "\e909";
  }

  .bfi-emoji:before {
    content: "\e91b";
  }

  .bfi-font-size:before {
    content: "\e920";
  }

  .bfi-fullscreen:before {
    content: "\e910";
  }

  .bfi-fullscreen-exit:before {
    content: "\e90d";
  }

  .bfi-help:before {
    content: "\e902";
  }

  .bfi-indent-decrease:before {
    content: "\e92f";
  }

  .bfi-indent-increase:before {
    content: "\e92e";
  }

  .bfi-info:before {
    content: "\e901";
  }

  .bfi-italic:before {
    content: "\e924";
  }

  .bfi-link:before {
    content: "\e91a";
  }

  .bfi-link-off:before {
    content: "\e919";
  }

  .bfi-list:before {
    content: "\e923";
  }

  .bfi-list-numbered:before {
    content: "\e922";
  }

  .bfi-menu:before {
    content: "\e908";
  }

  .bfi-more-horiz:before {
    content: "\e90b";
  }

  .bfi-more-vert:before {
    content: "\e90a";
  }

  .bfi-not-disturb:before {
    content: "\e907";
  }

  .bfi-print:before {
    content: "\e915";
  }

  .bfi-quote:before {
    content: "\e921";
  }

  .bfi-search:before {
    content: "\e916";
  }

  .bfi-settingsx:before {
    content: "\e917";
  }

  .bfi-share:before {
    content: "\e905";
  }

  .bfi-share-square:before {
    content: "\e900";
  }

  .bfi-strikethrough:before {
    content: "\e91f";
  }

  .bfi-text-color .path1:before {
    font-family: 'braft-icons' !important;
    content: "\e930";
    opacity: 0.36;
  }

  .bfi-text-color .path2:before {
    font-family: 'braft-icons' !important;
    content: "\e931";
    margin-left: -1em;
  }

  .bfi-underlined:before {
    content: "\e91d";
  }
}