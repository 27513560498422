.filterBtnBox {
  margin-left: auto;
  display: flex;
  cursor: pointer;

  @media (min-width: 992px) {
    display: none;
  }

  .filterBtn {
    border: 0;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-left: auto;

    &.showFilterBtn {
      .filterBtnText {
        color: #3091fd;
      }
    }
  }

  .filterBtnText {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.54px;
    color: #676767;
    margin-left: 8px;
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;