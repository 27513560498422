@import '@src/components/media/editor/assets/scss/_inc';

.bf-link-editor {
  width: 360px;
  padding-top: 25px;
  .input-group {
    margin: 0 15px 8px 15px;
    input {
      background-color: rgba(#fff, 0.07);
      border: none;
      border-radius: 2px;
      box-shadow: inset 0 0 0 1px rgba(#fff, 0.1);
      color: #fff;
      font-weight: bold;
      &:hover {
        box-shadow: inset 0 0 0 1px rgba($COLOR_ACTIVE, 0.5);
      }
      &:focus {
        box-shadow: inset 0 0 0 1px rgba($COLOR_ACTIVE, 1);
      }
      &:disabled {
        color: rgba(#fff, 0.7);
        box-shadow: none;
      }
    }
  }

  .switch-group {
    height: 16px;
    margin: 15px;
    .bf-switch {
      float: left;
    }
    label {
      float: left;
      margin-left: 15px;
      color: #999;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .buttons {
    box-sizing: content-box;
    height: 32px;
    margin-top: 20px;
    padding: 15px;
    overflow: hidden;
    box-shadow: inset 0 1px 0 0 rgba(#fff, 0.1);
    .button-remove-link {
      color: #999;
      font-size: 12px;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        color: $COLOR_DANGER;
      }
      i {
        margin-right: 5px;
        font-size: 16px;
      }
      i,
      span {
        display: block;
        float: left;
        line-height: 32px;
      }
    }
    button {
      margin-left: 10px;
      border-radius: 2px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
