:global {
  @import '~draft-js/dist/Draft.css';
}

@import '../editor/assets/scss/_base.scss';

@import './components/business/ControlBar/style.scss';
@import './components/business/EmojiPicker/style.scss';
@import './components/business/FontFamily/style.scss';
@import './components/business/FontSize/style.scss';
@import './components/business/Headings/style.scss';
@import './components/business/LetterSpacing/style.scss';
@import './components/business/LineHeight/style.scss';
@import './components/business/LinkEditor/style.scss';
@import './components/business/PlayerModal/style.scss';
@import './components/business/TextColor/style.scss';

@import './components/common/ColorPicker/style.scss';
@import './components/common/DropDown/style.scss';
@import './components/common/Modal/style.scss';
@import './components/common/Switch/style.scss';

@import './renderers/atomics/Audio/style.scss';
@import './renderers/atomics/Embed/style.scss';
@import './renderers/atomics/HorizontalLine/style.scss';
@import './renderers/atomics/Image/style.scss';
@import './renderers/atomics/Video/style.scss';
