@import '@src/components/media/editor/assets/scss/_inc';

.bf-image-size-editor,
.bf-image-link-editor {
  padding-bottom: 1px;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
  box-shadow: inset 0 -1px 0 0 rgba(#fff, 0.1);

  .editor-input-group {
    width: 320px;
    margin: 8px 10px;
    overflow: hidden;
  }

  input {
    display: block;
    float: left;
    box-sizing: content-box;
    height: 32px;
    margin: 0 5px 0 0;
    padding: 0 10px;
    background-color: rgba(#fff, 0.1);
    border: none;
    border-radius: 2px;
    outline: none;
    box-shadow: inset 0 0 0 1px rgba(#fff, 0.1);
    color: #fff;
    font-weight: bold;

    &:hover {
      box-shadow: inset 0 0 0 1px rgba($COLOR_ACTIVE, 0.5);
    }

    &:focus {
      box-shadow: inset 0 0 0 1px rgba($COLOR_ACTIVE, 1);
    }
  }

  button {
    float: left;
    width: 90px;
    height: 32px;
    margin: 0;
    padding: 0 20px;
    background-color: $COLOR_ACTIVE;
    border: none;
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      background-color: $COLOR_ACTIVE;
    }
  }
}

.bf-image-size-editor {
  input {
    width: 80px;
  }
}

.bf-image-link-editor {
  input {
    width: 185px;
  }

  .switch-group {
    height: 16px;
    margin: 10px 10px;

    .bf-switch {
      float: left;
    }

    label {
      float: left;
      margin-left: 15px;
      color: #999;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.bf-content .bf-image {
  position: relative;

  .bf-csize-icon {
    position: absolute;
    z-index: 2;
    width: 10px;
    height: 10px;
    background-color: rgba($COLOR_ACTIVE, 0.3);

    &.right-bottom {
      right: 0;
      bottom: 0;
      cursor: se-resize;
    }

    &.left-bottom {
      left: 0;
      bottom: 0;
      cursor: sw-resize;
    }
  }

  .bf-pre-csize {
    position: absolute;
    z-index: 1;
    background: transparent;

    &.rightbottom {
      left: 0;
      top: 0;
      border: 1px dashed deepskyblue;
    }

    &.leftbottom {
      right: 0;
      top: 0;
      border: 1px dashed deepskyblue;
    }
  }

  .bf-media-toolbar {
    &::before {
      visibility: hidden;
    }

    &[data-float='left'] [data-float='left'],
    &[data-float='right'] [data-float='right'],
    &[data-align='left'] [data-align='left'],
    &[data-align='center'] [data-align='center'],
    &[data-align='right'] [data-align='right'] {
      color: $COLOR_ACTIVE;
    }
  }
}