@import '@src/components/media/editor/assets/scss/_inc';

.bf-controlbar {
  margin: 0;
  padding: 0 5px;
  box-shadow: inset 0 -1px 0 0 rgba(#000, 0.2);

  &::after {
    display: block;
    content: '';
    clear: both;
  }

  button {
    padding: 0;
    outline: none;

    &[disabled] {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  [data-title] {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      z-index: 10;
      top: 100%;
      left: 50%;
      pointer-events: none;
      opacity: 0;
      transform: translateX(-50%) translateY(-5px);
      transition: opacity 0.3s, transform 0.3s;
    }

    &::before {
      margin-top: 3px;
      border: 5px solid transparent;
      border-bottom-color: $COLOR_DARK;
      content: '';
    }

    &::after {
      margin-top: 12px;
      padding: 5px;
      background-color: $COLOR_DARK;
      border-radius: 2px;
      box-shadow: 0 5px 15px rgba(#000, 0.2);
      color: #fff;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      content: attr(data-title);
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  input {
    outline: none;
  }

  .separator-line {
    display: block;
    float: left;
    height: 26px;
    width: 1px;
    margin: 10px;
    box-shadow: inset -1px 0 0 0 rgba(#000, 0.1);

    &+.control-item-group,
    &+.control-item {
      margin-left: 0;
    }

    &+.separator-line,
    &.last-child,
    &.first-child {
      display: none;
    }
  }

  .control-item-group {
    float: left;
    height: 36px;
    margin: 5px 0 5px 3px;

    &:first-child {
      margin-left: 0;
    }

    &>.control-item {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .dropdown-handler {
    border-radius: 2px;
  }

  .control-item {
    display: block;
    float: left;
    height: 36px;
    margin: 5px 0 5px 3px;
    border-radius: 2px;
    cursor: pointer;

    &.component-wrapper {
      cursor: default;
    }

    &:first-child {
      margin-left: 0;
    }

    &.button {
      box-sizing: border-box;
      min-width: 35px;
      padding: 0 8px;
      background-color: transparent;
      border: none;
      color: $COLOR_GRAY;
      font-size: 14px;

      &:hover {
        background-color: rgba(#000, 0.05);
      }

      &.active {
        color: $COLOR_ACTIVE;
      }

      i::before {
        display: block;
        height: 36px;
        font-size: 18px;
        line-height: 36px;
      }

      .bfi-undo,
      .bfi-redo {
        &::before {
          font-size: 14px;
        }
      }
    }
  }

  .dropdown {
    .control-item {
      width: 100%;
      float: none;
      margin: 0;
    }
  }
}