.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary);
  color: #ffffff;
  padding: 15px 20px;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  height: initial;
  border: 0;
  cursor: pointer;
  outline: 0;
  border-radius: 4px;

  &:focus {
    background-color: var(--secondaryDark);
    color: #ffffff;
  }

  &:hover {
    border: 0;
    background-color: var(--secondaryDark);
    color: #ffffff;
  }
}

.outlined {
  color: var(--secondary);
  background-color: #ffffff;
  border: 1px solid var(--secondary);

  &:focus {
    background-color: var(--secondaryDark);
    border: 1px solid var(--secondaryDark);
  }

  &:hover {
    background-color: var(--secondaryDark);
    border: 1px solid var(--secondaryDark);
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;