.footer {
  margin-top: auto;
  padding: 20px 35px 20px 35px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    padding: 15px;
  }

  .list {
    .item {
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
      margin-right: 20px;
      padding-right: 20px;

      &:not(:last-child) {
        border-right: 1px solid #979797;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .bottomDiv {
    display: inline-flex;
    margin-top: 15px;
    align-items: center;

    @media (max-width: 576px) {
      margin-top: 10px;
      flex-direction: column;
      align-items: flex-start;
    }

    .version {
      font-size: 12px;
      margin-bottom: 12px;
    }

    .linkDiv {
      cursor: pointer;
      margin-left: auto;
      display: inline-flex;
      align-items: center;
      font-size: 12px;

      @media (max-width: 576px) {
        margin-top: 10px;
        margin-left: 0px;
      }

      .logoImage {
        width: 24px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;