@import '@src/components/media/editor/assets/scss/_inc';

.text-color-dropdown {
  &.light-theme {
    .bf-color-switch-buttons {
      button {
        border-bottom: 1px solid #ccc;
        color: #616569;
        &.active {
          border-bottom-color: #3091fd;
          color: #3091fd;
        }
      }
    }
  }

  button.dropdown-handler {
    span {
      width: 36px;
      padding: 0;
      overflow: hidden;
      border-radius: 2px;
    }
  }

  .bf-text-color-picker-wrap {
    overflow: hidden;
  }

  .bf-color-switch-buttons {
    height: 36px;
    button {
      float: left;
      width: 50%;
      height: 36px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(#fff, 0.1);
      color: rgba(#fff, 0.5);
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        border-bottom-color: #3091fd;
        color: #3091fd;
      }
    }
  }
}
