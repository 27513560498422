@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);

@import '~react-alice-carousel/lib/alice-carousel.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~simplebar/dist/simplebar.css';
@import '~cropperjs/dist/cropper.css';
@import '~nprogress/nprogress.css';
@import '~braft-extensions/dist/table.css';
@import '~react-credit-cards/es/styles-compiled.css';
@import '../components/media/editor/styles.scss';

a,
body,
button,
div,
h1,
h2,
h3,
h4,
h5,
h6,
html,
p,
span {
  font-family: 'Roboto', 'Noto Sans TC', '微軟正黑體', sans-serif !important;
}

:root {
  --cardWidth: 400px;
}
html,
body {
  margin: 0 !important;
  font-size: 16px !important;
  font-family: 'Roboto', 'Noto Sans TC', '微軟正黑體', sans-serif !important;
  line-height: 1.5 !important;
  color: #222 !important;
  background-color: #f5f5f7 !important;
  overflow-y: overlay;
}

input:focus {
  outline: none;
}

::placeholder {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #676767;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

textarea {
  resize: none;
}
//rwd
@media (max-width: 576px) {
  :root {
    --cardWidth: 350px;
  }
  body {
    overflow-x: hidden;
  }
}

button {
  border: 0;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
}

//修改antd樣式
.ant-radio-button-wrapper-checked {
  background: #676767 !important;

  & span {
    color: white !important;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #676767 !important;
}

.ant-btn-primary[disabled] {
  border-radius: 2px !important;
  background-color: #d1d1d1 !important;

  span {
    font-size: 16px;
    color: #ffffff;
  }
}

.ant-select-selection-placeholder {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #676767;
  opacity: 1 !important;
  cursor: pointer;
}

.ant-popover-inner-content {
  padding: 0px !important;
}

// Override `react-toastify` style
.Toastify {
  display: flex;
  justify-content: center;
}

.Toastify__toast {
	min-height: 0;
	padding: 0;
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.65);
}

.Toastify__toast-body {
	padding: 12px 15px;
	margin: 0;
}

.Toastify__toast--success {
	border: 1px solid #78C90F;
	background-color: #F6FFED;
}

.Toastify__toast--warning {
	border: 1px solid #FA8B00;
	background-color: #FFF5E5;
}

.Toastify__toast--error {
	border: 1px solid #FFA39E;
	background-color: #FFF1F0;
}

.Toastify__toast--info {
	border: 1px solid #1890FF;
	background-color: #E7F4F9;
}

.Toastify__toast-container {
  width: unset;
  max-width: 728px;
  top: 1em;
  left: unset;
  margin-left: unset;
  @media (max-width: 575px) {
    max-width: 335px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 536px;
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}