.headings-dropdown {
  //min-width: 110px;

  .menu {
    width: 200px;
    overflow: hidden;

    .menu-item {
      padding: 15px 20px;
      text-align: left;
      line-height: initial;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        padding: 0;
        color: inherit;
      }

      h1 {
        font-size: 28px;
      }

      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
      }

      h5 {
        font-size: 14px;
      }

      h6 {
        font-size: 12px;
      }
    }
  }
}