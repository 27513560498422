@import '@src/components/media/editor/assets/scss/_inc';

.bf-content .bf-hr {
  position: relative;
  box-sizing: content-box;
  height: 15px;
  padding-top: 15px;
  text-align: center;
  &::before {
    display: block;
    height: 1px;
    background-color: rgba(#000, 0.1);
    content: '';
  }
  &:hover {
    &::before {
      background-color: rgba(#000, 0.3);
    }
    .bf-media-toolbar {
      display: block;
    }
  }
  .bf-media-toolbar {
    display: none;
  }
}
