.container {
  width: 100%;
  border-radius: 3px;
}

.inputDivMargin {
  margin-top: 20px;

  &:first-child {
    margin-top: 0px;
  }
}

.disabled {
  background-color: #d1d1d1;
  cursor: no-drop;
}

.input:disabled {
  .disabled;
}

.inputDivCol {
  display: inline-block;
  width: calc(50% - 5px);

  &.inputDivCol ~ .inputDivCol {
    margin-left: 10px;
  }
}

.inputItem {
  height: 45px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  border: solid 1px @border-color-base;
  padding: 0px 10px 0px 10px;

  &:focus-within {
    border: 1px solid black;
  }
}

.inputItemError {
  border: solid 1px @error-color;

  &:focus-within {
    border: solid 1px @error-color;
  }
}

.inputItemLast {
  border-top: 0px;
}

.inputView {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.checkIcon {
  position: relative;
  width: 0px;
  left: 10px;
}

.input {
  width: 100%;
  height: 33px;
  border: 0px !important;
  font-weight: 400;
}

.messageItem {
  display: inline-flex;
  width: 100%;
  background-color: white;

  .maxLengthItem {
    margin-left: auto;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
  }
}

.password {
  -webkit-text-security: disc;
}

@primary-color: #3091fd;@primary-dark-color: #005abe;@link-color: #3091fd;@success-color: #52c41a;@warning-color: #f98c2d;@error-color: #d0021b;@font-size-base: 14px;@heading-color: black;@text-color: #222222;@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 2px;@border-color-base: #dbdbdb;@box-shadow-base: 0 1px 15px 0 rgba(69, 65, 78, 0.08);@input-height-base: 45px;