@import '@src/components/media/editor/assets/scss/_inc';

.bf-dropdown {
  position: relative;
  width: auto;
  height: 36px;
  margin: 0;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &.light-theme {
    .dropdown-content {
      border: 1px solid #ccc;

      .dropdown-arrow {
        background-color: #fff;
        border: 1px solid #ccc;
      }

      .dropdown-content-inner {
        background-color: #fff;
      }
    }
  }

  .dropdown-content {
    box-sizing: content-box;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 50%;
    visibility: hidden;
    float: left;
    width: auto;
    min-width: 100%;
    margin-top: 9px;
    border-radius: 2px;
    box-shadow: 0 5px 15px rgba(#000, 0.2);
    opacity: 0;
    cursor: default;
    transform: translate(-50%, 20px);
    transition: 0.2s;

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(#fff, 0.3);
      border-radius: 2px;
    }

    .dropdown-arrow {
      position: absolute;
      z-index: 1;
      top: -3px;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: rgba($COLOR_DARK, 1);
      transform: translateX(-50%) rotate(45deg);
      transition: margin 0.2s;

      &.active {
        background-color: $COLOR_ACTIVE;
      }
    }

    .menu {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      border-radius: 2px;
    }

    .menu-item {
      display: block;
      list-style: none;
      margin: 0;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background-color: rgba(#000, 0.1);
      }

      &.active {
        background-color: $COLOR_ACTIVE;
        color: #fff;
      }

      &:not(.active) {
        color: rgba(#fff, 0.6);
        box-shadow: inset 0 -1px 0 0 rgba(#fff, 0.1);
      }
    }
  }

  .dropdown-content-inner {
    position: relative;
    z-index: 2;
    overflow: auto;
    background-color: rgba($COLOR_DARK, 1);
    border-radius: 2px;
  }

  .dropdown-handler {
    position: relative;
    display: block;
    //width: 100%;
    height: 36px;
    background-color: transparent;
    border: none;
    color: $COLOR_GRAY;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000, 0.05);
    }

    * {
      display: inline;
      padding: 0;
      font-size: inherit;
      font-weight: normal;
    }

    >span {
      float: left;
      padding: 0 5px;
      font-size: 14px;
      line-height: 36px;
      pointer-events: none;

      i {
        display: block;
        height: 36px;
        font-size: 16px;
        line-height: 36px;
        text-align: center;
      }
    }

    .bfi-drop-down {
      float: right;
      width: 20px;
      height: 36px;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      pointer-events: none;
    }
  }

  &.active {
    .dropdown-handler {
      background-color: rgba(#000, 0.05);
    }

    .dropdown-content {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}