@import '@src/components/media/editor/assets/scss/_inc';

.bf-video-player video {
  display: block;
  width: 640px;
  max-width: 80vw;
  height: auto;
  margin: 0 10px 10px 10px;
  object-fit: contain;
}
